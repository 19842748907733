.select-color {}

.select-color>div {
    margin-bottom: 8px;
}

.img-blocks {
    display: flex;
    margin-top: 150px;
}

.img-blocks>img {
    outline: 2px solid transparent;
    transition: 0.1s all;
    cursor: pointer;
}

.img-blocks>img:hover {
    outline: 2px solid #34B9A0;
}

.img-blocks-skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
    0% {
        background-color: #C1D0CE;
    }

    100% {
        background-color: #a5aead;
    }
}

.size-box-selected {
    background-color: #34B9A0 !important;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.size-box {
    background-color: #EFF1F1;
    width: 64px;
    height: 67px;
    display: flex;
    margin-right: 8px;
    border-radius: 4px;
    flex-direction: column;
    align-items: center;
    padding: 5px 15px 4px;
    cursor: pointer;
    font-size: 8px;
    font-weight: 500;
    font-family: 'Montserrat';
    transition: background-color .2s ease-in-out;
}

.size-box:hover {
    background-color: #C1D0CE;
}

.size-box__top {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.size-box__bottom {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.size-box__bottom-text {
    font-size: 10px;
    font-weight: 600;
}

.color-tooltip {
    background-color: rgb(52, 183, 159);
    border-radius: 6px;
    color: #333;
    font-family: Montserrat;
    padding: 5px 0;
    position: absolute;
    text-align: center;
    visibility: hidden;
    width: 120px;
    z-index: 1;
    margin-top: -34px;
    font-size: 12px;
    font-weight: 700;
}

.color-box {}

.color-box:hover .color-tooltip {
    visibility: visible;
}

.img-blocks:nth-child(3) img:first-child {
    margin-left: 0px !important;
}

@media (max-width: 1100px) {
    .img-blocks {
        justify-content: center;
        flex-wrap: wrap;
        max-width: 500px;
    }
    .img-blocks div,
    .img-blocks img {
        margin-left: 15px !important;
    }
    .img-blocks div:nth-child(1),
    .img-blocks div:nth-child(2),
    .img-blocks img:nth-child(1),
    .img-blocks img:nth-child(2) {
        margin-bottom: 15px !important;
    }
}