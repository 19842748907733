.editor-toolbar {
    padding: 16px 24px !important;
    width: 645px;
    margin-bottom: 8px;
    background-color: white;
    border: none !important;
    border-radius: 8px;
}

.editor-toolbar .ql-formats {
    position: relative;
    margin-right: 32px !important;
}

.editor-toolbar .ql-formats:nth-child(3) {
    margin-right: 44px !important;
}

.editor-toolbar .ql-formats::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -16px;
    width: 1px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.2);
}

.editor-toolbar .ql-formats:nth-child(1):after {
    right: -20px;
}

.editor-toolbar .ql-formats:nth-child(3):after {
    right: -32px;
}

.editor-toolbar .ql-formats:last-child::after {
    display: none;
}

.editor-toolbar .ql-formats:last-child {
    margin-bottom: 9px;
}

.editor-toolbar .ql-picker.ql-expanded .ql-picker-label {
    border-color: transparent !important;
}

.editor-toolbar .ql-align .ql-picker-label::after {
    content: url('../../assets/icons/next.svg');
    position: absolute;
    top: 50%;
    right: -12px;
    transform: translateY(-50%) rotate(90deg) scale(0.8);
    filter: brightness(0%);
}

/* Font Family */

.editor-toolbar .ql-font {
    min-width: 120px;
    margin-right: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.editor-toolbar .ql-font .ql-picker-label {
    overflow: hidden;
}

.editor-toolbar .ql-font .ql-picker-label svg {
    display: none;
}

.editor-toolbar .ql-font .ql-picker-label::after {
    content: url('../../assets/icons/next.svg');
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%) rotate(90deg) scale(0.8);
    filter: brightness(0%);
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
    font-family: "Arial", cursive;
    ;
    content: "Arial" !important;
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
    font-family: "Courier New", cursive;
    ;
    content: "Courier New" !important;
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
    font-family: "Georgia", cursive;
    ;
    content: "Georgia" !important;
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
    font-family: "Helvetica", cursive;
    ;
    content: "Helvetica" !important;
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="lucida-sans"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="lucida-sans"]::before {
    font-family: "Lucida Sans", cursive;
    ;
    content: "Lucida Sans" !important;
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
    font-family: "Tahoma", cursive;
    ;
    content: "Tahoma" !important;
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="times-new-roman"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="times-new-roman"]::before {
    font-family: "Times New Roman", cursive;
    ;
    content: "Times New Roman" !important;
}

.editor-toolbar .ql-picker.ql-font .ql-picker-label[data-value="trebuchet-ms"]::before,
.editor-toolbar .ql-picker.ql-font .ql-picker-item[data-value="trebuchet-ms"]::before {
    font-family: "Trebuchet MS", cursive;
    ;
    content: "Trebuchet MS" !important;
}

.ql-font-arial {
    font-family: "Arial";
}

.ql-font-courier-new {
    font-family: "Courier New";
}

.ql-font-georgia {
    font-family: "Georgia";
}

.ql-font-lucida-sans {
    font-family: "Lucida Sans";
}

.ql-font-helvetica {
    font-family: "Helvetica";
}

.ql-font-tahoma {
    font-family: "Tahoma";
}

.ql-font-times-new-roman {
    font-family: "Times New Roman";
}

.ql-font-trebuchet-ms {
    font-family: "Trebuchet MS";
}

/* Font style */

.ql-textStyle {
    width: 103px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.editor-toolbar .ql-textStyle .ql-picker-label svg {
    display: none;
}

.editor-toolbar .ql-textStyle .ql-picker-label::after {
    content: url('../../assets/icons/next.svg');
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%) rotate(90deg) scale(0.8);
    filter: brightness(0%);
}

.ql-textStyle .ql-picker-item::before,
.ql-textStyle .ql-picker-label::before {
    content: 'Custom'
}

.ql-textStyle [data-value="normal"]::before {
    content: 'Normal'
}

.ql-textStyle [data-value="bold"]::before {
    content: 'Bold'
}

.ql-textStyle [data-value="italic"]::before {
    content: 'Italic';
}

.ql-textStyle [data-value="underline"]::before {
    content: 'Underline';
}

/* Font Size */

.editor-toolbar .ql-size {
    width: 40px !important;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.editor-toolbar .ql-size svg {
    display: none;
}

.editor-toolbar .ql-size .ql-picker-label {
    padding-left: 0;
}

.editor-toolbar .ql-size .ql-picker-label::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.editor-toolbar .ql-formats .ql-decreaseSize,
.editor-toolbar .ql-formats .ql-increaseSize {
    position: relative;
}

.editor-toolbar .ql-formats .ql-decreaseSize::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 12px;
    background-color: black;
}

.editor-toolbar .ql-formats .ql-increaseSize::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 2px;
    width: 12px;
    background-color: black;
}

.editor-toolbar .ql-formats .ql-increaseSize::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    height: 2px;
    width: 12px;
    background-color: black;
}

/* Color */

.editor-toolbar .ql-color .ql-picker-label::after {
    content: url('../../assets/icons/next.svg');
    position: absolute;
    top: 40%;
    right: -15px;
    transform: rotate(90deg) scale(0.8);
    filter: brightness(0%);
}

.editor-toolbar .ql-formats .ql-color-picker {
    width: 38px;
}

.editor-toolbar .ql-formats .ql-color-picker .ql-picker-label svg {
    border-radius: 4px;
}

.editor-toolbar .ql-formats .ql-color-picker .ql-picker-label svg polyline {
    display: none;
}

.editor-toolbar .ql-formats .ql-color-picker .ql-picker-label svg line:last-child {
    display: none;
}

.editor-toolbar .ql-formats .ql-color-picker .ql-picker-label svg line:first-child {
    display: initial;
    stroke-width: 30px;
}

.editor-toolbar .ql-transparent {
    opacity: 1;
}

.editor-toolbar .ql-formats .ql-color-picker .ql-picker-options {
    margin-top: 10px !important;
    left: 5px;
}