.createCampaign-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14201E99;
    backdrop-filter: blur(4px);
    z-index: 9999;
}

.createCampaign-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 520px;
    padding: 28px 24px;
    border-radius: 8px;
    background-color: white;
}

.createCampaign-modal__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
}

.createCampaign-modal__top-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    color: #14201E;
}

.createCampaign-modal__top-close {
    width: 32px;
    height: 32px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.createCampaign-modal__top-close img {
    filter: invert(100%);
    transform: scale(0.6);
}

.createCampaign-modal__form {

}

.createCampaign-modal__form-group {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.createCampaign-modal__form-label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: #14201E;
}

.createCampaign-modal__form-input {
    width: 100%;
    padding: 15px 10px;
    font-family: inherit;
    outline: none;
    border: none;
    background-color: #EFF1F1;
    border-radius: 8px;
}

.createCampaign-modal__form-channel {
    background-color: #EFF1F1;
    color: #14201E;
    font-size: 12px;
    line-height: 14.5px;
    font-family: inherit;
    font-weight: 500;
    border-radius: 24px;
    padding: 10px 16px;
    border: none;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.createCampaign-modal__form-select:hover {
    background-color: #e1e1e1;
}

.createCampaign-modal__form-submit {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.createCampaign-modal__form-submit button {
    font-size: 14px;
    font-family: inherit;
    line-height: 17px;
    font-weight: 500;
    color: #14201E;
    background-color: #41D1B6;
    padding: 13px 67px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.createCampaign-modal__form-submit button:hover {
    background-color: #25A38B;
}

.createCampaign-modal__form-submit button:disabled {
    background-color: #34B9A0;
    color: rgba(20, 32, 30, 0.4);
    cursor: context-menu;
}