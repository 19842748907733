.editText-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14201E99;
    backdrop-filter: blur(4px);
    z-index: 9999;
}
.editText-modal > .editor-toolbar{
    background-color: #DFE6E6 !important;
}
.editText-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 50px 20px 50px;
    border-radius: 8px;
    background-color: #ffffff;
}

.editText-modal__top {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 32px;
    height: 32px;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.editText-modal__top img {
    filter: invert(59%) sepia(91%) saturate(295%) hue-rotate(119deg) brightness(87%) contrast(95%);
}

.editText-modal__editor {
    width: 645px;
    height: 40vh;
    background-color: #DFE6E6 !important;
    border-radius: 8px !important;
    margin-bottom: 20px;
}
.quill.editText-modal__editor > * {
    border: none !important;
}

.editText-modal__bottom {
    display: flex;
    justify-content: flex-end;
}

.editText-modal__bottom button {
    padding: 15px 47px;
    font-size: 14px;
    line-height: 17px;
    font-family: "Montserrat";
    font-weight: 500;
    color: #14201E;
    background-color: #34B9A0;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.editText-modal__bottom button:hover {
    background-color: #25A38B;
}

.editText-modal__bottom button:active {
    background-color: #41D1B6;
}