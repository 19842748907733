.attrTooltip {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-left: 8px;
    position: relative;
    cursor: pointer;
}

.attrTooltip-content {
    position: relative;
    background-color: #14201E;
    padding: 12px 16px !important;
    border-radius: 8px !important;
    font-size: 10px !important;
    line-height: 12px !important;
    font-weight: 500 !important;
}

.attrTooltip-content .attrTooltip-arrow {
    border-top: 1px solid rgba(239, 241, 241, 0.2);
    border-right: 1px solid rgba(239, 241, 241, 0.2);
}