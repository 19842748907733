.campaignDetails {
    padding-top: 50px;
    font-family: 'Montserrat', sans-serif;
}
.campaignDetails-container {
    max-width: 1024px;
    width: 100%;
    margin: 0 auto;
}
.campaignDetails-top {
    margin-bottom: 32px;
}

.campaignDetails-top__back {
    margin-bottom: 25px; 
    display: flex; 
    justify-content: space-between;
}

.campaignDetails-top__back-button {
    background: none; 
    border: none;  
    cursor: pointer;
    font-family: inherit;
    display: flex;
    align-items: center;
}

.campaignDetails-top__back-button__img {
    margin-top: 1px;
    margin-right: 15px;
    filter: brightness(10);
}

.campaignDetails-top__back-button__text {
    color: #E0E0E0; 
    font-size: 16px; 
    line-height: 19px; 
    font-weight: 600;
}

.campaignDetails-top__info {
    display: flex;
    align-items: center;
}

.campaignDetails-top__info-name {
    margin-right: 16px;
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    color: #34B9A0;
}

.campaignDetails-top__info-editName {
    margin-right: 12px;
    cursor: pointer;
    transform: scale(0.8);
}

.campaignDetails-top__info-separator {
    margin-right: 16px;
    width: 2px;
    height: 35px;
    background-color: #EFF1F1;
    opacity: 0.1;
}

.campaignDetails-top__info-status {
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
}

.campaignDetails-top__info-status span:first-child {
    color: #748E8C;
}

.campaignDetails-top__info-btn {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

.campaignDetails-top__info-btn button {
    padding: 15px 56px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-family: inherit;
    border-radius: 4px;
    border: none;
    color: #14201E;
    background-color: #34B9A0;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.campaignDetails-top__info-btn button:hover {
    background-color: #25A38B;
}

/* .campaignDetails-controls__bottom {
    display: flex;
    justify-content: space-between;
}

.campaignDetails-controls__bottom-button {
    width: 25%;
    padding: 15px 0;
    text-align: center;
    background-color: #23312F;
    color: #2F8E7D;
    cursor: pointer;
    font-size: 16px;
    line-height: 19.5px;
    border: none;
    border-right: 1px solid #14201E;
    font-family: inherit;
}
.campaignDetails-controls__bottom-button:last-child {
    border-bottom: none;
}

.bottom-button__active {
    position: relative;
}
.bottom-button__active::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #2F8E7D;
    width: 100%;
    height: 2px;
} */