.preview-btn {
    border: none;
    background: transparent;
    font-size: 12px;
    line-height: 14px;
    font-weight: 500;
    color: white;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}

.constructor-side-block {}

.constructor-side-block::-webkit-scrollbar {
    display: none;
}

.letter-block > div > html::-webkit-scrollbar {
    display: none;
}

@media(max-width: 1500px) {
    .constructor-side-block {
        width: 20vw !important;
    }
}