.ai-progress-table {
    margin-top: 64px;
}

.ai-progress-table>tr>td {

    font-family: 'Montserrat';
    z-index: 0;
    position: relative;
    color: #748E8C;
}

.ai-progress-table>tr {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
}

.ai-progress-table>tr>td:last-child {

    padding-left: 12px;
    display: block;

}

.ai-side-block>span>.attr-block>.attr-label {
    color: #23312F;
}

.ai-side-block>span>div>.attr-block>.attr-label {
    color: #23312F;
}

.ai-side-block>span>div>.attr-block>div>div>div>div>img {
    filter: brightness(60%);
}

.ai-side-block>span>div>.attr-block>div>div>div>div>input {
    background: #EFF1F1;
    border: 1px solid #00000033;
    color: #23312F;
}

.ai-side-block>span>div>.attr-block>div>button>img {
    filter: brightness(60%);
}

.ai-side-block>span>div>.attr-block>input {
    background: #EFF1F1;
    border: 1px solid #00000033;
    color: #23312F;
}

.ai-side-block>span>div>.attr-block>div>div>input {
    background: #EFF1F1;
    border: 1px solid #00000033;
    color: #23312F;
}

.ai-side-block>span>div>div>.attr-block>.custom-select>p {
    background: #EFF1F1;
    border: 1px solid #00000033;
    color: #23312F;
}

.ai-side-block>span>.attr-block {
    border-color: #00000033;
}

.ai-side-block>span>div>.attr-block {
    border-color: #00000033;
}

.ai-side-block>span>div>div>.attr-block {
    border-color: #00000033;
}

.ai-side-block>span>div>div>.attr-block>.custom-align>img {
    filter: brightness(60%);
}

.ai-side-block>span>div>div>.attr-block>input {
    background: #EFF1F1;
    border: 1px solid #00000033;
    color: #23312F;
}

.ai-side-block>span>div>div>.attr-block>.custom-align__checked {
    background: #C1D0CE;
}

.ai-side-block>span>div>div>.attr-block>.custom-align__checked:hover {
    background: #c1d0ce65;
}

.ai-side-block>span>div>div>.attr-block>.attr-label {
    color: #23312F;
}

.ai-side-block>span>div>.attr-block>button>img {
    filter: brightness(60%);
}

.ai-side-block>span>div>.attr-block>button:hover {
    background: #c1d0ce65;
}

.ai-side-block>span>div>.attr-block>.custom-align__checked {
    background: #C1D0CE;
}

.ai-side-block>span>div>div>div>div>.custom-select-options {
    background: #C1D0CE;
}
.ai-side-block>span>div>div>div>div> p > img{
    filter: brightness(0%) !important;
}
.ai-progress-table>tr>td:first-child {
    background-color: #34B9A0;
    border-radius: 100%;
    font-size: 14px;
    padding: 3px 0 0 0;
    height: 24px;
    text-align: center;
    width: 24px;
}

.ai-side-left {
    padding-top: 55px;
    padding-left: 20px;
}

.ai-control {
    display: flex;
    justify-content: space-between;
}
.loading_ai{
    animation: pulse 4s infinite;
    transition: 0.3s all;
}
@keyframes pulse {
	0% {
		transform: scale(0.75);
	}

	70% {
		transform: scale(1.25);
	}

	100% {
		transform: scale(0.75);
	}
}
.ai-control-back {
    background: white;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-size: 14px;
    color: #23312F;
    padding: 19px 0;
    width: 148px;
    outline: none;
    border: 1px solid #23312F;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.ai-control-back:hover {
    background: rgb(248, 248, 248);
}

.ai-control-next:hover {
    background: #34b9a19f;
}

.ai-control-next {
    background: #34B9A0;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-size: 14px;
    color: white;
    padding: 19px 0;
    width: 148px;
    outline: none;
    border: none;
    transition: 0.3s all ease-in-out;
    cursor: pointer;
}

.ai-progress-table>tr>td>div {
    transition: all 0.3s;
}

.ai-category-main {
    margin-top: 64px;
}

.ai-select-category {
    margin-bottom: 8px;
}

.ai-category-main>h1 {

    font-family: 'Montserrat';
    color: #23312F;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;
}

.ai-category-main>hr {
    color: rgba(0, 0, 0, 0.2);
    margin-bottom: 24px;
}

.ai-select-category>p {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    padding: 19px 16px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    background-color: #EFF1F1;
    cursor: pointer;
}

.img_selected_rotate {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(2787%) hue-rotate(117deg) brightness(83%) contrast(81%);
    transition: 0.3s all;
    rotate: 180deg;
}

.input-header {
    margin-top: 48px;
}

.input-header>h3 {
    font-size: 24px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 16px;
}

.input-header>hr {
    color: #00000033;
    margin-bottom: 24px;
}

.input-header>p {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
}

.input-box {
    margin-bottom: 24px;
    position: relative;
}

.input-box>p {
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-bottom: 8px;
}

.input-box>input {
    background: #EFF1F1;
    border: none;
    border-radius: 8px;
    padding: 14px 16px;
    width: 100%;
    color: #333;
    font-size: 14px;
    line-height: 20px;
}

.input-box>span {
    color: #748E8C;
    font-size: 12px;
    position: absolute;
    bottom: 16px;
    font-family: 'Montserrat', sans-serif;
    right: 16px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    cursor: default;
}

.input-box>textarea {
    background: #EFF1F1;
    border: none;
    border-radius: 8px;
    padding: 14px 16px;
    max-height: 128px;
    height: 128px;
    font-family: 'Montserrat', sans-serif;
    resize: none;
    width: 100%;
    color: #333;
    font-size: 14px;
    line-height: 20px;
}

.ai-select-category>p>img {
    position: absolute;
    right: 22px;
    top: 40%;
}

.ai-select-category>ul {
    margin-top: 19px;
}

.ai-select-category>ul>li {
    font-size: 14px;
    list-style: none;
    padding-bottom: 19px;
    font-family: 'Montserrat';
    margin-left: 32px;
    cursor: pointer;
}