.about-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14201E99;
    backdrop-filter: blur(4px);
    z-index: 9999;
}

.about-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 460px;
    height: 200px;
    padding: 24px;
    border-radius: 4px;
    background-color: #23312F;
}

.about-modal-top {
    margin-bottom: 40px;
    display: flex;
}

.about-modal-img {
    margin-right: 20px;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #40504E;
    border-radius: 4px;
}

.about-modal-img img {
    width: 63px;
}

.about-modal-content {
    display: flex;
    flex-direction: column;
}

.about-modal-title {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Montserrat';
    line-height: 19px;
    color: white;
}

.about-modal-subtitle {
    color: white;
    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
    font-family: 'IBM Plex Sans', sans-serif;
}

.about-modal-text {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    font-size: 10px;
    line-height: 13px;
    color: rgba(255, 255, 255, 0.2);
    font-family: 'IBM Plex Sans', sans-serif;
}

.about-modal-text a {
    color: rgba(255, 255, 255, 0.2);
    margin-right: 9px;
    text-decoration: underline;
}

.about-modal-text span {
    margin-right: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 1px;
    height: 12px;
}

.about-modal-bottom {
    width: 100%;
    text-align: right;
}
.about-modal-button {
    padding: 8px 59px;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Montserrat';
    font-weight: 600;
    color: #14201E;
    background-color: #34B9A0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.about-modal-button:hover {
    background-color: #31a28e;
}

.about-modal-button:active {
    background-color: #41D1B6;
}