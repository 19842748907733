.deleteCampaign-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14201E99;
    backdrop-filter: blur(4px);
    z-index: 9999;
}

.deleteCampaign-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 520px;
    padding: 38px 48px 48px;
    border-radius: 8px;
    background-color: white;
    font-family: 'Montserrat';
}

.deleteCampaign-modal-title {
    text-align: center;
    font-size: 25px;
    margin-bottom: 60px;
}

.deleteCampaign-modal-buttons {
    display: flex;
    justify-content: space-between;
}

.deleteCampaign-modal-buttons button {
    border-radius: 4px;
    border: none;
    padding: 12px 60px;
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: inherit;
    transition: background-color .2s ease-in-out;
}

.deleteCampaign-modal-buttons button:first-child {
    background-color: #F36F56;
}
.deleteCampaign-modal-buttons button:first-child:hover {
    background-color: #c55e4a;
}
.deleteCampaign-modal-buttons button:first-child:active {
    background-color: #ff8d76;
}

.deleteCampaign-modal-buttons button:last-child {
    background-color: #34B9A0;
}
.deleteCampaign-modal-buttons button:last-child:hover {
    background-color: #25A38B;
}
.deleteCampaign-modal-buttons button:last-child:active {
    background-color: #41D1B6;
}