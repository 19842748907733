.faq {
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: 12px;
    line-height: 15px;
    font-weight: 700;
    display: flex;
    align-items: flex-start;
}

.faq-left {
    padding-top: 16px;
    max-width: 380px;
    width: 100%;
    background-color: #23312F;
    border-right: 1px solid rgba(239, 241, 241, 0.2);
    height: calc(100vh - 50px);
}

.faq-left-list-btn {
    padding: 14px 24px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.faq-right {
    width: 100%;
    padding: 40px;
    max-height: calc(100vh - 50px);
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.faq-right::-webkit-scrollbar {
    display: none;
}

.accordion-item {
    margin-bottom: 8px;
}

.accordion-item-question {
    display: flex;
    cursor: pointer;
}

.accordion-item-question button {
    width: 95%;
    color: #E0E0E0;
    background-color: #202D2B;
    padding: 22px 24px;
    border: none;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.accordion-item-question-img {
    width: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #23312F;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.accordion-item-question-img img {
    filter: brightness(1000%);
    transition: all .3s ease-in-out;
}

.accordion-item-answer {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows .3s ease-in-out;
}

.accordion-item-answer.accordion-item-answer_open {
    grid-template-rows: 1fr;
}

.accordion-item-answer_inner {
    overflow: hidden;
}

.accordion-item-answer_content {
    padding: 24px;
    background-color: #23312F;
    font-weight: 300;
    font-size: 14px;
    line-height: 24px;
    color: #E0E0E0;
    white-space: pre-line;
}

.accordion-item_active .accordion-item-question button {
    border-bottom-left-radius: 0px;
} 
.accordion-item_active .accordion-item-question-img {
    border-bottom-right-radius: 0px;
}