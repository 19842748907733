* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Montserrat', sans-serif; */
}

body {
  background-color: #14201E;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
  z-index: 1;
}

.ReactModal__Overlay {
  z-index: 1;
}

a {
  text-decoration: none;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9";
  font-size: 9px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
  font-size: 10px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
  font-size: 11px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 18px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26";
  font-size: 26px !important;
}

.ql-snow .ql-size .ql-picker-item[data-value="28px"]::before {
  content: "28";
  font-size: 28px !important;
}

.higlighted__parentElem {
  position: relative;
}

.higlighted__parentElem:after {
  content: attr(data-content);
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.higlighted__parentElem:hover.higlighted__parentElem:after {
  /* display: block; */
}

/* .higlighted__parentElem:hover {
  box-shadow: 0 0 0 2px #34B9A0 inset !important;
  transition: all .2s ease-in-out;
} */
.highlighted__childElem {
  position: relative;
}

.highlighted__childElem:after {
  content: attr(data-content);
  position: absolute;
  top: 0;
  right: 0;
  display: none;
}

.highlighted__childElem:hover.highlighted__childElem:after {
  /* display: block; */
}

/* .highlighted__childElem:hover {
  box-shadow: 0 0 0 2px #34B9A0 inset !important;
} */
.higlighted__parentElem:has(.highlighted__childElem:hover) {
  box-shadow: 0 0 0 0 #34B9A0 inset !important;
}

.higlighted__parentElem:has(.highlighted__childElem:hover).higlighted__parentElem:after {
  display: none;
}

.progress {
  position: absolute;
  top: 33%;
  left: 50%;
  transform: translate(-50%, -33%);
  width: 95%;
  height: 2px;
  background-color: #5F7675;
  z-index: -1;
}

.progress-line {
  position: absolute;
  top: 30%;
  left: 0;
  transform: translateY(-30%);
  width: 95%;
  height: 2px;
  background-color: #34B9A0;
  z-index: -1;
  transition: all .2s ease-in-out;
}

/* Custom radio */

.custom-radio {
  width: 100%;
  height: 48px;
  position: relative;
  margin-bottom: 8px;
  font-family: 'Montserrat', sans-serif;
}

.custom-radio__input {
  appearance: none;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #23312F;
  border-radius: 4px;
  cursor: pointer;
}

.custom-radio__input:checked {
  background-color: #34B9A0;
}

.custom-radio__input:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #E0E0E0;
  border-radius: 50%;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-radio__input:checked::after {
  border: 1px solid black;
}

.custom-radio__input:checked::before {
  content: '';
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: black;
  border-radius: 50%;
}

.custom-radio__label {
  position: absolute;
  left: 45px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 19.5px;
  color: #E0E0E0;
  cursor: pointer;
}

.custom-radio__input:checked~.custom-radio__label {
  color: #14201E;
}


/* Custom checkbox */

.custom-checkbox {
  width: 100%;
  height: 48px;
  position: relative;
  margin-bottom: 8px;
  font-family: 'Montserrat', sans-serif;
}

.custom-checkbox__input {
  appearance: none;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #23312F;
  border-radius: 4px;
  cursor: pointer;
}

.custom-checkbox__input:checked {
  background-color: #34B9A0;
}

.custom-checkbox__input::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  border: 1px solid #E0E0E0;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.custom-checkbox__input:checked::after {
  border: 1px solid black;
}

.custom-checkbox__input:checked::before {
  content: '';
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: black;
}

.custom-checkbox__label {
  position: absolute;
  left: 45px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 19.5px;
  color: #E0E0E0;
  cursor: pointer;
}

.custom-checkbox__input:checked~.custom-checkbox__label {
  color: #14201E;
}


.menu {
  width: 150px;
  background: #23312F;
  padding: 17px 0 17px 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -55px;
  z-index: 100;
  width: 55px;
  display: none;
  border-radius: 0px 20px 20px 0px;
}

.menu-moveBottom .menu {
  top: 0;
  transform: translateY(0);
}

.higlighted__parentElem:hover .parentMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.higlighted__parentElem:has(.highlighted__childElem:hover) .parentMenu {
  display: none !important;
}

.highlighted__childElem:hover .childMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  margin-bottom: 10px;
  transition: all .2s ease-in-out;
}

.menuButton:hover {
  filter: invert(59%) sepia(91%) saturate(295%) hue-rotate(119deg) brightness(87%) contrast(95%);
}

.menuButton:last-child {
  margin-bottom: 0;
}

.attr-label {
  font-size: 16px;
  font-family: 'Inconsolata';
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.attr-input {
  font-size: 16px;
  font-family: 'Montserrat';
  width: 180px;
  padding: 4px 12px;
  border: 1px solid #EFF1F133;
  border-radius: 4px;
  background-color: #23312f;
  color: white;
  font-size: 12px;
  outline: none;
  transition: all 0.1s;
}

.attr-input-text {
  font-family: 'Montserrat', sans-serif;
  margin-right: 10px;
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: white;
}

.attr-file-input {
  display: none;
}

.attr-file-label {
  display: inline-flex;
  padding: 15px 46px;
  background-color: #34B9A0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  color: #14201E;
  font-weight: 500;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  transition: background-color .2s ease-in-out;
}

.attr-file-label:hover {
  background-color: #25A38B;
}

.attr-file-label:active {
  background-color: #41D1B6;
}

.attr-input:focus {
  background-color: #2f3c3a;
  border-color: #34B9A0;
}

.attr-input:hover {
  background-color: #2f3c3a;
}

.left-menu {
  width: 300px;
  height: 82%;
  position: fixed;
  top: 105px;
  left: 0;
  overflow-y: scroll;
  background-color: #14201E;
}

.left-menu::-webkit-scrollbar {
  display: none;
}

.left-menu__tabs {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  z-index: 1;
}

.tabs-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #2a8675;
}

.tabs-row__button {
  width: 50%;
  padding: 10px 0;
  font-size: 20px;
  border: none;
  background-color: #2a8675;
  cursor: pointer;
  border-right: 1px solid #243c39;
}

.left-menu__content {
  background-color: #243c39;
}

.left-menu__content-item {
  color: white;
}

.controls-layout {
  display: flex;
  justify-content: space-between;
}

.controls-layout__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.controls-layout__button {
  margin-bottom: 10px;
  font-size: 15px;
  border: none;
  background-color: #34B9A0;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.constructor-menu {}

.constructor-menu>li {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.icon-transform {
  transition: 0.1s all;
}

.constructor-menu>li:hover {
  background-color: black;
  transition: 0.1s all;
}

.constructor-menu li:first-child {
  border-bottom: 1px solid #EFF1F133;
}

.constructor-block {
  padding: 18px;
  font-size: 12px;
  color: white;
  border-bottom: 1px solid #EFF1F133;
  cursor: pointer;
  font-weight: 700;
  font-family: 'Montserrat';
  transition: background-color .2s ease-in-out;
}

.constructor-block:hover {
  background-color: #2F3C3A;
}

.constructor-block>p>img {
  margin-right: 8px;
}

.constructor-block>div>p {
  font-weight: 400;
  margin-top: 12px;
  transition: color .2s ease-in-out;
}

.constructor-block>div>p:hover {
  color: #34B9A0;
}

.constructor-block__img {
  transition: all .2s ease-in-out;
}

.constructor-block>div>p:hover .constructor-block__img {
  filter: invert(59%) sepia(91%) saturate(295%) hue-rotate(119deg) brightness(87%) contrast(95%);
}

.attr-block {
  border-bottom: 1px solid #EFF1F133;
  padding: 24px;
}

.attr-label {
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 700;
  color: white;
}

.attr-link {
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  transition: all .1s ease-in-out;
  cursor: pointer;
}

.attr-link:hover {
  background-color: #2F3C3A;
}

.attr-link__active {
  background-color: #5F7675;
}

.attr-link__active:hover {
  background-color: #5F7675;
}

.attr-link__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.attr-generate-img {
  background-color: #34B9A0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 15px 18px;
  display: flex;
  align-items: center;
  transition: background-color .2s ease-in-out;
}

.attr-generate-img:hover {
  background-color: #25A38B;
}

.attr-generate-img:active {
  background-color: #41D1B6;
}

.attr-generate-img img {
  margin-right: 4px;
  filter: invert(100%);
}

.attr-generate-img span {
  font-size: 14px;
  line-height: 17px;
  font-family: 'Montserrat';
  font-weight: 500;
}

.custom-select-options {
  background-color: #23312f;
  border-radius: 4px;

  max-height: 120px;
  position: relative;
  margin-top: 12px;
  overflow-x: scroll;
  border: 1px solid #EFF1F133;
}

.custom-select-options::-webkit-scrollbar {
  display: none;
}

.select-input {
  font-family: 'Montserrat';
  color: white;
  font-size: 12px;
  cursor: pointer;
  padding: 8px;
}

.custom-select>p {
  font-family: 'Montserrat';
  font-size: 12px;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid #EFF1F133;
  transition: 0.1s all;
  cursor: pointer;
}

.custom-select>p:hover {
  background-color: #2F3C3A;

}

.select-input:hover {
  background-color: #2f3c3a;
  transition: 0.1s all;
}

.attr-block>select {
  margin-top: 12px;
  color: white;
  border-radius: 4px;
}

.custom-align {
  width: 24px;
  height: 24px;
  margin-right: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color .2s ease-in-out;
}

.custom-align:hover {
  background-color: #2F3C3A;
}

.custom-align__checked {
  background-color: #5F7675;
}

.custom-align__checked:hover {
  background-color: #5F7675;
}

.attr-reset {
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  transition: background-color .1s ease-in-out;
}

.attr-reset:hover {
  background-color: #2f3c3a;
}

.toast-success,
.toast-error {
    border-radius: 8px;
    font-family: 'Montserrat';
    padding: 14px 50px 14px 16px;
    font-size: 12px;
    font-weight: 500;
    color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
    min-height: auto;
}

.toast-error {
    background-color: #F36F56;
}
.toast-success {
    background-color: #2A9481;
}

.toast-success .Toastify__toast-body,
.toast-error .Toastify__toast-body {
    padding: 0;
}

.toast-success .Toastify__close-button,
.toast-error .Toastify__close-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    opacity: 1;
}

.toast-success .Toastify__close-button > svg,
.toast-error .Toastify__close-button > svg {
    height: 20px;
    width: 20px;
    margin-top: 1px;
}