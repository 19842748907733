.detailsCreative-content {
    display: flex;
    justify-content: space-between;
}

.detailsCreative-saved__title {
    margin-left: 24px;
    margin-bottom: 12px; 
    font-size: 9px;
    line-height: 11px; 
    color: #748E8C; 
    font-weight: 500;
}

.detailsCreative-saved__list {
    width: 360px;
    list-style-type: none;
}

.detailsCreative-saved__list-item {
    display: flex;
    position: relative;
    background-color: #202D2B;
    margin-bottom: 2px;
}

.detailsCreative-saved__list-item__title {
    padding-left: 24px;
    width: 312px; 
    height: 64px;
    display: flex;
    align-items: center; 
}

.detailsCreative-saved__list-item__title-text {
    color: #C1D0CE;
}

.detailsCreative-saved__list-item__button {
    width: 64px; 
    height: 64px; 
    background: none;
    border: none;
    cursor: pointer;
    font-family: inherit;
    background-color: #23312F;
}

.detailsCreative-saved__list-item__menu {
    position: absolute;
    top: 0;
    right: -176px;
    width: 174px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    z-index: 100;
}
.detailsCreative-saved__list-item__menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
.detailsCreative-saved__list-item__menu-button {
    position: relative;
    z-index: 100;
    width: 100%;
    padding: 14px 16px;
    text-align: left;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    font-family: inherit;
    border: none;
    background: #23312F;
    color: #C1D0CE;
    border-bottom: 1px solid #5F7675;
    cursor: pointer;
    transition: color .2s ease-in-out;
}
.detailsCreative-saved__list-item__menu-button:last-child {
    border-bottom: none;
}
.detailsCreative-saved__list-item__menu-button:hover {
    color: #34B9A0;
}

.detailsCreative-content__preview {
    margin-top: 25px;
    width: 603px;
    min-height: 900px;
    border: none;
    outline: none;
}