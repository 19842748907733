.header {
    padding: 16px;
    border-bottom: 1px solid rgba(239, 241, 241, 0.2);
    font-family: 'Montserrat', sans-serif;
    position: relative;
}

.header-logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.header-menu {
    position: absolute;
    margin-top: 28px;
    width: 174px;
    color: white;
    background-color: #14201e;
    list-style: none;
    border: 1px solid #EFF1F133;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.45);
    z-index: 2 !important;
    overflow: hidden;
}

.header-menu-item {
    cursor: pointer;
    transition: 0.1s all;
    padding: 18px;
    display: block;
    color: inherit;
}

.header-menu-item:hover {
    background-color: black;
}