.previewModal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14201E99;
    backdrop-filter: blur(4px);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.previewModal {
    padding: 24px 24px 0 24px;
    width: 65%;
    height: 80%;
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 13px 0px rgba(20, 32, 30, 1);
    border-radius: 4px;
}

.previewModal-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.previewModal-top__title {
    font-size: 14px;
    line-height: 24px;
    color: #14201E;
    font-weight: 700;
}

.previewModal-top__btn {
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
}

.previewModal-top__btn img {
    filter: invert(100%);
}

.previewModal-actions {
    width: 100%;
}

.previewModal-actions__top {
    display: flex;
    margin-bottom: 8px;
}

.previewModal-actions__top-input {
    padding: 0 24px;
    width: 85%;
    border: none;
    outline: none;
    background-color: #EFF1F1;
    font-family: inherit;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.previewModal-actions__top-input::placeholder {
    color: #14201E;
    opacity: 0.6;
    font-size: 14px;
    line-height: 20px;
    font-family: inherit;
    font-weight: 400;
}

.previewModal-actions__top-btn {
    padding: 15px 0;
    width: 15%;
    border: none;
    background-color: #34B9A0;
    color: #14201E;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    font-family: inherit;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.previewModal-actions__top-btn:hover {
    background-color: #31a28e;
}

.previewModal-actions__top-btn:disabled {
    background-color: #34B9A0;
    color: rgba(20, 32, 30, 0.4);
    cursor: context-menu;
}

.previewModal-actions__bottom {
    margin-bottom: 32px;
    padding: 6px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EFF1F1;
    border-radius: 8px;
}

.previewModal-actions__bottom-btnGenerate {
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin-right: 8px;
    padding: 7px 10px;
    background-color: #34B9A0;
    border: none;
    font-family: inherit;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.previewModal-actions__bottom-btnGenerate:hover {
    background-color: #31a28e;
}

.previewModal-actions__bottom-btnGenerate img {
    filter: invert(100%);
    margin-right: 4px;
}

.previewModal-actions__bottom-btnGenerate span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
}

.previewModal-actions__bottom-arrows {
    margin-right: 16px;
}

.previewModal-actions__bottom-arrows-left,
.previewModal-actions__bottom-arrows-right {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
    
}

.previewModal-actions__bottom-arrows-left:hover img,
.previewModal-actions__bottom-arrows-right:hover img {
    filter: invert(38%) sepia(91%) saturate(295%) hue-rotate(119deg) brightness(87%) contrast(95%);
}

.previewModal-actions__bottom-arrows-left {}

.previewModal-actions__bottom-arrows-left img {
    transform: rotate(90deg);
    width: 18px;
    transition: all .2s ease-in-out;
}

.previewModal-actions__bottom-arrows-right img {
    transform: rotate(-90deg);
    width: 18px;
    transition: all .2s ease-in-out;
}

.previewModal-actions__bottom-input {
    flex-grow: 1;
    border: none;
    background-color: transparent;
    font-family: inherit;
    outline: none;
    padding-right: 30px;
}

.previewModal-actions__bottom-input::placeholder {
    font-size: 14px;
    line-height: 20px;
    font-family: inherit;
    font-weight: 400;
    color: #14201E;
}

.previewModal-actions__bottom-btnPreText {
    border: none;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #14201E;
    font-family: inherit;
    text-decoration: underline;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.previewModal-actions__bottom-btnPreText:hover {
    color: #14201E;
    opacity: 0.6;
}

.previewModal-iframe {
    height: 100%;
    border: none;
    outline: none;
    align-self: center;
}