.editCampaignName-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14201E99;
    backdrop-filter: blur(4px);
    z-index: 9999;
}

.editCampaignName-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 520px;
    padding: 28px 24px 24px;
    border-radius: 8px;
    background-color: white;
    font-family: 'Montserrat';
}

.editCampaignName-modal-top {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.editCampaignName-modal-top h3 {
    font-size: 25px;
    line-height: 32px;
    color: #14201E;
}

.editCampaignName-modal-top img {
    filter: invert(100);
    transform: scale(0.7);
    cursor: pointer;
}

.editCampaignName-modal-input {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.editCampaignName-modal-input label {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    color: #14201E;
    font-weight: 700;
}

.editCampaignName-modal-input input {
    border: none;
    background-color: #EFF1F1;
    border-radius: 8px;
    padding: 10px 24px;
    outline: none;
    font-size: 14px;
    color: #14201E;
    font-family: inherit;
}

.editCampaignName-modal-input p {
    font-size: 12px;
    color: #FD7D66;
    margin-left: 24px;
    margin-top: 5px;
}

.editCampaignName-modal-buttons {
    display: flex;
    justify-content: space-between;
}

.editCampaignName-modal-buttons button {
    border-radius: 4px;
    border: none;
    width: 48%;
    padding: 12px 0;
    cursor: pointer;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: inherit;
    transition: background-color .2s ease-in-out;
}

.editCampaignName-modal-buttons button:first-child {
    background-color: #EFF1F1;
}
.editCampaignName-modal-buttons button:first-child:hover {
    background-color: #e1e1e1;
}
.editCampaignName-modal-buttons button:first-child:active {
    background-color: #EFF1F1;
}

.editCampaignName-modal-buttons button:last-child {
    background-color: #34B9A0;
}
.editCampaignName-modal-buttons button:last-child:hover {
    background-color: #25A38B;
}
.editCampaignName-modal-buttons button:last-child:active {
    background-color: #41D1B6;
}