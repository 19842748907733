.ai-text-screen {
    background-color: white;
    width: 100vw;
    height: 100vh;
    /* overflow-y: scroll; */
    display: flex;
    flex-direction: column;
}

.ai-text-content {
    display: flex;
    /* height: 100%; */
    flex-grow: 1;
    padding-top: 62px;
}

.ai-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 21px 16px;
    border-bottom: 1px solid #00000033;
    z-index: 100;
    background-color: white;
}

.ai-text-params {
    max-width: 380px;
    min-width: 380px;
    height: calc(100vh - 62px);
    position: relative;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #00000033;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ai-text-params::-webkit-scrollbar {
    display: none;
}

.ai-text-box {
    height: calc(100vh - 62px);
    position: relative;
    background-color: #eff1f1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.apply-ai-text {
    /* position: absolute; */
    /* bottom: 16px; */
    margin-bottom: 17px;
    background-color: #34B9A0;
    padding: 16px 32px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Montserrat';
    margin-left: 416px;
    transition: background-color .2s ease-in-out;
}

.apply-ai-text:hover {
    background-color: #25A38B;
}

.apply-ai-text:active {
    background-color: #41D1B6;
}

.apply-ai-text-btn {
    margin-left: 416px;
    margin-bottom: 17px;
    /* align-self: flex-end; */
}

.apply-ai-text-btn p {
    background-color: #34B9A0;
    padding: 16px 32px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-family: 'Montserrat';
    transition: background-color .2s ease-in-out;
}

.apply-ai-text-btn p:hover {
    background-color: #25A38B;
}

.apply-ai-text-btn p:active {
    background-color: #41D1B6;
}

.generated-text {
    position: relative;
    width: 600px;
    height: 80vh;
    margin-top: 8px;
    margin-bottom: 8px;
    max-height: 634px;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    padding: 16px 24px;
    white-space: pre-wrap;
    /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;
    /* Mozilla, since 1999 */
    white-space: -pre-wrap;
    /* Opera 4-6 */
    white-space: -o-pre-wrap;
    /* Opera 7 */
    word-wrap: break-word;
    /* Internet Explorer 5.5+ */
    line-height: 24px;
    background-color: white;
    border-radius: 8px;
}

.generate-button {
    border-radius: 4px;
    background-color: #34B9A0;
    padding: 15px 47px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.generate-button:hover {
    background-color: #25A38B;
}

.generate-button:active {
    background-color: #41D1B6;
}

.ai-text-params>p>img {
    transition: 0.1s all;
}

.ai-text-params>div>p {
    font-family: 'Montserrat';
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 14px;
}

.ai-text-params>div>textarea {
    width: 327px;
    outline: none;
    background-color: #EFF1F1;
    border-radius: 8px;
    resize: vertical;
    border: none;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    padding: 16px 24px;
    min-height: 130px;
    max-height: 800px;
}

.manual-words {
    width: 150px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.manual-words>input {
    border: none;
    outline: none;
    background-color: #EFF1F1;
    border-radius: 24px;
    font-family: 'Montserrat';
    width: 65px;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    font-weight: 500;
    padding: 12px 15px;
    font-size: 12px;
    border: 1px solid transparent;
    transition: border-color .2s ease-in-out;
    text-align: center;
}

.manual-words>input:focus {
    border: 1px solid #34B9A0;
}

.manual-words>input::-webkit-outer-spin-button,
.manual-words>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.manual-words>i {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 40px;
    font-style: normal;
    font-size: 28px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    color: #34B9A0;
    font-family: 'Montserrat';
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.manual-words>i:hover {
    background-color: #EFF1F1;
}

.select-words {
    list-style-type: none;
    margin-left: 20px;
    margin-top: 8px;
}

.select-words>li {
    float: left;
    text-decoration: underline;
    margin-right: 16px;
    color: #14201E;
    cursor: pointer;
    transition: color .2s ease-in-out;
    font-family: 'Montserrat', sans-serif;
}

.select-words>li:hover {
    color: #34B9A0;
}

.words-block {
    margin-top: 16px;
}

.select-tone {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
}

.select-tone>div {
    margin-bottom: 30px;
}

.tone-label {
    background-color: #EFF1F1;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Montserrat';
    border-radius: 24px;
    margin-right: 8px;
    cursor: pointer;
    color: #14201E;
    padding: 12px 16px;
    transition: background-color .2s ease-in-out;
}

.tone-input:hover+.tone-label {
    background-color: #C1D0CE;
}

.tone-input:checked+.tone-label {
    background-color: #34B9A0;
}

.tone-input {
    display: none;
}

@media (max-width: 1000px) {
    .generated-text {
        width: 96%;
    }
    .apply-ai-text-btn {
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: flex-end;
        padding-right: 12px;
    }
}

.editorText {
    width: 600px;
    height: 70vh;
    background-color: white;
    border-radius: 8px !important;
}

.quill.editorText > * {
    border: none !important;
}