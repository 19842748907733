.generateLetter-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #14201E99;
    backdrop-filter: blur(4px);
    z-index: 9999;
    cursor: auto;
}

.generateLetter-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    padding: 28px 24px;
    border-radius: 8px;
    background-color: white;
    color: #14201E;
}

.generateLetter-modal-close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 28px;
}

.generateLetter-modal-close button {
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.generateLetter-modal-close img {
    filter: invert(100%);
}

.generateLetter-row {
    display: flex;
    justify-content: space-between;
}

.generateLetter-row {
    margin-bottom: 30px;
}

.generateLetter-col {
    width: 42%;
    display: flex;
    flex-direction: column;
}

.generateLetter-col-title {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 700;
    color: #14201E;
}

.generateLetter-describe {
    border: none;
    outline: none;
    background-color: #EFF1F1;
    resize: none;
    border-radius: 8px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 14px;
    padding: 16px 24px;
}

.generateLetter-words {}

.generateLetter-words-manual {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.generateLetter-words-manual>i {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
    color: #34B9A0;
    font-family: 'Montserrat';
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.generateLetter-words-manual>i:first-child {
    margin-right: 8px;
}

.generateLetter-words-manual>i:last-child {
    margin-left: 8px;
}

.generateLetter-words-manual>i:hover {
    background-color: #EFF1F1;
}

.generateLetter-words-manual>input {
    border: none;
    outline: none;
    background-color: #EFF1F1;
    border-radius: 24px;
    font-family: 'Montserrat';
    width: 65px;
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
    -moz-appearance: textfield;
    font-weight: 500;
    padding: 12px 15px;
    font-size: 12px;
    border: 1px solid transparent;
    transition: border-color .2s ease-in-out;
    text-align: center;
}

.generateLetter-words-manual input:focus {
    border: 1px solid #34B9A0;
}

.generateLetter-words-manual>input::-webkit-outer-spin-button,
.generateLetter-words-manual>input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.generateLetter-words-select {
    display: flex;
    list-style-type: none;
}

.generateLetter-words-select>li {
    margin-right: 16px;
    font-weight: 400;
    font-size: 16px;
    color: #14201E;
    text-decoration: underline;
    cursor: pointer;
    transition: color .2s ease-in-out;
    font-family: 'Montserrat', sans-serif;
}

.generateLetter-words-select>li:hover {
    color: #34B9A0;
}

.generateLetter-select {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}

.generateLetter-select > div {
    margin-bottom: 30px;
}

.generateLetter-select-input {
    display: none;
}

.generateLetter-select-label {
    background-color: #EFF1F1;
    font-size: 12px;
    font-weight: 500;
    font-family: 'Montserrat';
    border-radius: 24px;
    margin-right: 8px;
    cursor: pointer;
    color: #14201E;
    padding: 12px 16px;
    transition: background-color .2s ease-in-out;
}

.generateLetter-select-input:hover+.generateLetter-select-label {
    background-color: #C1D0CE;
}

.generateLetter-select-input:checked+.generateLetter-select-label {
    background-color: #34B9A0;
}

.generateLetter-modal-generate {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.generateLetter-modal-generate > button {
    display: flex;
    align-items: center;
    border: none;
    background-color: #34B9A0;
    border-radius: 4px;
    width: 180px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color .2s ease-in-out;
}

.generateLetter-modal-generate > button:hover {
    background-color: #25A38B;
}

.generateLetter-modal-generate > button:active {
    background-color: #41D1B6;
}

.generateLetter-modal-generate > button:disabled {
    background-color: #34B9A0;
    color: rgba(20, 32, 30, 0.4);
    cursor: context-menu;
}
.generateLetter-modal-generate > button:disabled > img {
    filter: invert(100%) opacity(0.3);
}

.generateLetter-modal-generate > button > img {
    filter: invert(100%);
    margin-right: 4px;
}

.generateLetter-modal-generate > button > span {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: inherit;
}